import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"

import Layout from "../components/layout"
import SEO from "../components/seo"

import UpRight from "../svgs/up-right.svg"

const NotFoundPage = () => (
  <Layout>
    <div className="vertical-line"></div>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p style={{ marginBottom: `var(--gutter)` }}>Nothing here.</p>
    <p>
      Head back{" "}
      <TransitionLink
        className="link arrow-link"
        to="/"
        exit={{ length: 0 }}
        entry={{ length: 1, delay: 0.1 }}
      >
        <span>{"home "}</span>
        <UpRight className="arrow" />
      </TransitionLink>
    </p>
  </Layout>
)

export default NotFoundPage
