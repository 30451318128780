import React from "react"
import PropTypes from "prop-types"
import TransitionLink from "gatsby-plugin-transition-link"

import Close from "../svgs/close.svg"
import Logo from "../svgs/logo-h.svg"
import LogoV from "../svgs/logo-v.svg"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <header className="header">
        <TransitionLink
          className="home-link"
          to={`/`}
          exit={{ length: 0 }}
          entry={{ length: 0.5, delay: 0.01 }}
        >
          <Logo className="page logo-here" />
          <LogoV className="page logo-here-v" />
          <span className="sr-only">Go to home page</span>
        </TransitionLink>
        <TransitionLink
          to={`/`}
          className="page close-button"
          exit={{ length: 0 }}
          entry={{ length: 0.5, delay: 0.01 }}
        >
          <span>Close Page</span>
          <Close className="icon-close" />
        </TransitionLink>
      </header>
      <div className="page-content">{children}</div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
